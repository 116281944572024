<template>
  <el-dialog
    v-el-drag-dialog
    :title="$l('contract.fileInfo','附件信息')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1000px"
    top="18vh"
    class="small-padding">
    <el-button
      style="margin: 5px 0 5px 0"
      size="small"
      @click="handleAdd">
      {{$l("contract.addFile", "添加附件")}}
    </el-button>
    <el-table
      :key="tableKey"
      v-loading="contentLoading"
      :data="tableData"
      max-height="500px"
      border>
      <el-table-column type="index" :label="$l('maintWorkOrder.no','序号')" width="45px" align="center"></el-table-column>
      <el-table-column prop="attachmentName" :label="$l('contract.fileName','附件名称')"></el-table-column>
      <el-table-column prop="createTime" :label="$l('contract.uploadTime','上传时间')"></el-table-column>
      <el-table-column prop="creator" :label="$l('contract.uploader','上传人')"></el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        width="120px"
        :label="$t('common.operation')">
        <template v-slot="scope">
          <el-upload
            v-if="scope.row.id === ''"
            ref="upload"
            name="files"
            style="display: inline-block; margin-right: 5px"
            accept=".jpg, .png, .bmp, .jpeg, .xlsx, .xls, .docx, .doc"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            :on-success="function(res, file, fileList) {return onUploadSuccess(res, file, fileList, scope.$index)}"
            :on-error="onUploadError"
            :headers="uploadHeaders"
            :disabled="uploadLoading"
            :data="uploadData"
            :file-list="tableData[scope.$index].fileList">
            <el-tooltip slot="trigger" :content="$l('contract.upload','上传')" placement="left" effect="light">
              <el-button
                size="mini"
                type="success"
                icon="el-icon-upload"
                round
                :loading="uploadLoading">
              </el-button>
            </el-tooltip>
          </el-upload>
          <el-tooltip v-if="scope.row.id > 0" :content="$l('contract.download','下载')" placement="left" effect="light">
            <el-button
              size="mini"
              type="success"
              icon="el-icon-check"
              round
              @click="handleDownload(scope)"></el-button>
          </el-tooltip>
          <el-tooltip :content="$l('contract.delete','删除')" placement="right" effect="light">
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              round
              @click="handleDelete(scope.row, scope.$index)">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import auth from "@/util/auth";

  const moduleName = "systemFile";
  export default {
    components: {},
    data() {
      return {
        contentLoading: false,
        dialogVisible: false,
        uploadLoading: false,
        tableData: [],
        tableKey: "",
        contractId: "",
        contractFile: {
          id: "",
          referId: "",
          referType: "",
          module: "",
          attachmentName: "",
          attachmentPath: "",
          creator: "",
        },
        uploadUrl: window.config.SERVER_URL + moduleName,
        uploadHeaders: {Authorization: auth.getToken()},
        uploadData: {
          module: "Contract",
          referId: "",
        },
      };
    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        this.contractId = row.id;
        this.getData(row.id);
        this.uploadData.referId = row.id;
      },
      getData(id) {
        this.contentLoading = true;
        let param = {
          module: "Contract",
          referId: id,
        };
        this.$api.getData(moduleName, param).then(res => {
          this.tableData = res.data;
          this.contentLoading = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      handleAdd() {
        let contractFile = JSON.parse(JSON.stringify(this.contractFile));
        this.tableData.push(contractFile);
      },
      handleDelete(row, index) {
        if (row.id === "") {
          this.tableData.splice(index, 1);
        } else {
          this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            this.$api.deleteById(moduleName, row.id).then(() => {
              this.tableData.splice(index, 1);
              this.$message.success(this.$t("common.tip.deleteSuccess"));
            }).catch((error) => {
              if (error.response) {
                this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
              }
            });
          });
        }
      },
      handleDownload(scope) {
        window.open(window.config.SERVER_URL + scope.row.attachmentPath);
      },
      beforeUpload(file) {
        let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
        let allowType = ["jpg", "png", "bmp", "jpeg", "xlsx", "xls", "docx", "doc"];
        if (allowType.indexOf(fileType.toLowerCase()) === -1) {
          this.$message.error(this.$l("contract.uploadTypeLimit", "只能上传后缀名为jpg、png、bmp、jpeg、xlsx、xls、docx、doc的文件"));
          return false;
        }
        if (file.size > 10 * 1024 * 1024) {
          this.$message.error(this.$l("contract.uploadSizeLimit", "上传文件大小不能超过10M"));
          return false;
        }
        this.uploadLoading = true;
        return true;
      },
      onUploadSuccess(response, file, fileList, index) {
        this.uploadLoading = false;
        this.$emit("on-success");
        this.$message.success(this.$l("contract.uploadSuccess", "上传成功"));
        this.tableData[index] = response[0];
        this.tableKey = Math.random();
      },
      onUploadError(err) {
        this.uploadLoading = false;
        if (!err.message) {
          this.$message.error(this.$l("contract.uploadFailure", "上传失败"));
        } else {
          this.$message.error(this.$l("contract.uploadFailure", "上传失败") + ": " + JSON.parse(err.message).message);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
