<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('contract.check','查看')"
    width="1000px"
    top="3vh">
    <span style="line-height: 25px;font-weight: 600;font-size: 15px;;">
      {{$l("workOrder.elevatorInfo", "电梯信息")}}
    </span>
    <el-card style="margin-bottom:20px;" shadow="never">
      <el-tag
        v-for="tag in model.elevatorList"
        :key="tag.id"
        size="medium"
        style="margin-right: 10px">
        <router-link v-if="tag.id" :to="'/elevator/'+tag.id" target="_blank">
          {{tag.no}}-{{tag.name}}
        </router-link>
      </el-tag>
    </el-card>
    <span style="line-height: 25px;font-weight: 600;font-size: 15px;">
      {{$l("contract.contractInfo", "合同相关信息")}}
    </span>
    <div style="margin-top: 10px">
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.contractNo", "合同号")}}：</span>
          <span>{{model.contractNo}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.contractSource", "合同来源")}}：</span>
          <span v-if="$i18n.isCn">{{model.contractSource}}</span>
          <span v-else>{{model.contractSourceEn}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.contractStartDate", "合同起日")}}：</span>
          <span>{{model.startDate}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.contractEndDate", "合同止日")}}：</span>
          <span>{{model.endDate}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("elevator.useUnit", "使用单位")}}：</span>
          <span>{{model.useUnitName}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.siteName", "现场名称")}}：</span>
          <span>{{model.siteName}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.packagingForm", "包料形式")}}：</span>
          <span v-if="$i18n.isCn">{{model.packagingForm}}</span>
          <span v-else>{{model.packagingFormEn}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.branchName", "分支机构")}}：</span>
          <span>{{model.branchName}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.inspectionUndertaker", "年检承担方")}}：</span>
          <span v-if="$i18n.isCn">{{model.inspectionUndertaker}}</span>
          <span v-else>{{model.inspectionUndertakerEn}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.speedLimiterUndertaker", "限速器承担方")}}：</span>
          <span v-if="$i18n.isCn">{{model.speedLimiterUndertaker}}</span>
          <span v-else>{{model.speedLimiterUndertakerEn}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.brakeTestFeeUndertaker", "制动试验费用承担方")}}：</span>
          <span v-if="$i18n.isCn">{{model.brakeTestFeeUndertaker}}</span>
          <span v-else>{{model.brakeTestFeeUndertakerEn}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.freeAccessoriesQuota", "免费配件额度")}}：</span>
          <span v-if="model.freeAccessoriesQuota">{{model.freeAccessoriesQuota}}元</span>
          <span v-else></span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.contractPrice", "合同价款")}}：</span>
          <span v-if="model.contractPrice">{{model.contractPrice}}元</span>
          <span v-else></span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.paymentMethod", "合同付款方式")}}：</span>
          <span v-if="$i18n.isCn">{{model.paymentMethod}}</span>
          <span v-else>{{model.paymentMethodEn}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.monthlyUnitPrice", "月单价")}}：</span>
          <span v-if="model.monthlyUnitPrice">{{model.monthlyUnitPrice}}元</span>
          <span v-else></span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.agencyFees", "代理费")}}：</span>
          <span v-if="model.agencyFees">{{model.agencyFees}}元</span>
          <span v-else></span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.contactName", "联系人")}}：</span>
          <span>{{model.contactName}}</span>
        </div>
        <div>
          <span class="contract-info">{{$l("contract.contactInformation", "联系方式")}}：</span>
          <span>{{model.contactInformation}}</span>
        </div>
      </div>
      <div class="vm-separate">
        <div>
          <span class="contract-info">{{$l("contract.isSubcontract", "是否分包")}}：</span>
          <span v-if="model.isSubcontract">{{$l("common.yes", "是")}}</span>
          <span v-else>{{$l("common.no", "否")}}</span>
        </div>
        <div></div>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        useUnitList: [],
        branchList: [],
        isCopy: false,
        model: {
          id: 0,
          branchId: 0,
          branchName: "",
          useUnitId: 0,
          useUnitName: "",
          contractNo: "",
          contractSource: "",
          contractSourceEn: "",
          startDate: "",
          endDate: "",
          siteName: "",
          packagingForm: "",
          packagingFormEn: "",
          inspectionUndertaker: "",
          inspectionUndertakerEn: "",
          speedLimiterUndertaker: "",
          speedLimiterUndertakerEn: "",
          brakeTestFeeUndertaker: "",
          brakeTestFeeUndertakerEn: "",
          freeAccessoriesQuota: "",
          monthlyUnitPrice: "",
          paymentMethod: "",
          paymentMethodEn: "",
          agencyFees: "",
          contractPrice: "",
          contactName: "",
          contactInformation: "",
          isSubcontract: 0,
          elevatorList: [],
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        if (this.model.id) {
          this.getData();
        }
      },
      getData() {
        this.$http
          .get("contract/" + this.model.id)
          .then((res) => {
            this.model = res.data;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
.vm-separate {
  font-size: 14px;
  margin-bottom: 15px;
}

.contract-info {
  text-align: left;
  margin-left: 20px;
}
</style>