<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="contract"
      @selection-change="handleSelectionChange" @row-click="handleRowClick">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px"
            clearable></el-input>
        </vm-search>
        <vm-search :label="$l('contract.branchAgency', '分支机构')">
          <el-select v-model="search.branchId" :placeholder="$t('common.pleaseSelect')" style="width: 140px" clearable>
            <el-option v-for="item in branchList" :key="item.id" :label="item.branchName" :value="item.id"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('contract.packagingForm', '包料形式')">
          <el-select v-model="search.packagingForm" :placeholder="$t('common.pleaseSelect')" style="width: 120px"
            clearable>
            <el-option v-for="item in packagingFormList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('contract.contractSource', '合同来源')">
          <el-select v-model="search.contractSource" :placeholder="$t('common.pleaseSelect')" style="width: 120px"
            clearable>
            <el-option v-for="item in contractSourceList" :key="item.name" :label="$i18n.isCn ? item.name : item.desc"
              :value="item.name"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('contract.expiredStatus', '到期情况')">
          <el-select v-model="search.isOverdue" :placeholder="$t('common.pleaseSelect')" style="width: 140px" clearable>
            <el-option v-for="item in isOverdueList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$l('contract.contractStartDate', '合同起日')">
          <el-date-picker v-model="search.startTime" style="width:140px" value-format="yyyy-MM-dd" type="date" clearable
            :placeholder="$l('yearCheck.date', '选择日期')">
          </el-date-picker>
        </vm-search>
        <vm-search :label="$l('contract.contractEndDate', '合同止日')">
          <el-date-picker v-model="search.endTime" style="width:140px" value-format="yyyy-MM-dd" type="date" clearable
            :placeholder="$l('yearCheck.date', '选择日期')">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0, false)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
        <el-button @click="copyContract()">
          {{ $l("contract.copy", "复制") }}
        </el-button>
        <el-button class="dangerBtn" @click="batchDelete()">
          {{ $l("contract.batchDelete", "批量作废") }}
        </el-button>
        <el-button :disabled="fileDisable" @click="handleFileUpload">
          {{ $l("contract.appendix", "附件") }}
        </el-button>
        <el-upload :action="uploadUrl" :before-upload="beforeUpload" :on-success="onUploadSuccess"
          :on-error="onUploadError" :on-exceed="onUploadExceed" :show-file-list="false" :headers="uploadHeaders"
          accept=".xlsx" :limit="1" :disabled="importLoading" style="display: inline-block;margin: 0 10px">
          <el-button :loading="importLoading">
            {{ $l("contract.importContract", "导入合同") }}
          </el-button>
        </el-upload>
        <el-button class="greenBtn" @click="downloadTemplate()">
          {{ $l("elevatorNetApply.downloadTemplate", "下载导入模板") }}
        </el-button>
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{ $l("elevator.exportExcel", "导出") }}
        </el-button>
      </template>
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column prop="contractNo" :label="$l('contract.contractNo', '合同号')" width="120px">
        <template v-slot="scope">
          <a @click="$refs.contractDetails.open(scope.row.id)">
            {{ scope.row.contractNo }}
          </a>
        </template>
      </el-table-column>
      <el-table-column prop="branchName" :label="$l('contract.branchAgency', '分支机构')" :width="$l('120px', '110px')"
        align="center"></el-table-column>
      <el-table-column prop="contractSource" :label="$l('contract.contractSource', '合同来源')" :width="$l('120px', '90px')"
        align="center">
        <template v-slot="scope">
          <div v-if="$i18n.isCn">
            <span>{{ scope.row.contractSource }}</span>
          </div>
          <div v-else>
            <span>{{ scope.row.contractSourceEn }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="useUnitName" :label="$l('elevator.useUnit', '使用单位')"></el-table-column>
      <el-table-column prop="siteName" :label="$l('contract.siteName', '现场名称')"></el-table-column>
      <el-table-column prop="startDate" :label="$l('contract.contractStartDate', '合同起日')" :width="$l('140px', '100px')"
        align="center"></el-table-column>
      <el-table-column prop="endDate" :label="$l('contract.contractEndDate', '合同止日')" :width="$l('140px', '100px')"
        align="center"></el-table-column>
      <el-table-column :label="$l('contract.remindExpired', '提醒到期')" :width="$l('150px', '100px')" align="center">
        <template v-slot="scope">
          <div v-if="scope.row.isOverdue === 1">
            <el-tag type="danger">{{ $l("contract.beExpired", "已到期") }}</el-tag>
          </div>
          <div v-if="scope.row.isOverdue === 2">
            <el-tag type="warning">{{ $l("contract.expiresMonth", "一个月内到期") }}</el-tag>
          </div>
          <div v-if="scope.row.isOverdue === 3">
            <el-tag>{{ $l("contract.expiresThreeMonths", "三个月内到期") }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="packagingForm" :label="$l('contract.packagingForm', '包料形式')" :width="$l('120px', '90px')"
        align="center">
        <template v-slot="scope">
          <div v-if="$i18n.isCn">
            {{ scope.row.packagingForm }}
          </div>
          <div v-else>
            {{ scope.row.packagingFormEn }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="contractPrice" :label="$l('contract.contractPrice', '合同价款')" :width="$l('160px', '90px')"
        align="center"></el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="80" fixed="right">
        <template v-slot="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id, false)">
            {{ $l("elevatorNetApply.edit", "编辑") }}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <contract-details ref="contractDetails"></contract-details>
    <contract-file ref="contractFile" @on-success="getList(-1)"></contract-file>
  </div>
</template>

<script>
import auth from "@/util/auth";
import EditPage from "./ContractEdit";
import ContractDetails from "./ContractDetails";
import ContractFile from "@/views/contract/ContractFile";

export default {
  components: { EditPage, ContractDetails, ContractFile },
  data() {
    return {
      importLoading: false,
      uploadUrl: window.config.SERVER_URL + "contract/importContract",
      uploadHeaders: { Authorization: auth.getToken() },
      loading: true,
      exportLoading: false,
      fileDisable: false,
      multiSelect: [],
      branchList: [],
      packagingFormList: [],
      contractSourceList: [],
      search: {
        filter: "",
        packagingFor: "",
        branchId: "",
        contractSource: "",
        startTime: "",
        endTime: "",
        isOverdue: "",
      },
      isOverdueList: [
        { value: 1, label: this.$l("contract.beExpired", "已到期") },
        { value: 2, label: this.$l("contract.expiresMonth", "一个月内到期") },
        { value: 3, label: this.$l("contract.expiresThreeMonths", "三个月内到期") },
      ],
    };
  },
  mounted() {
    this.getList(1);
    this.getBranchList();
    this.getPackagingFormList();
    this.getContractSourceList();
  },
  methods: {
    getList(pageNum) {
      if (this.$route.params.isOverdue != null) {
        this.search.isOverdue = this.$route.params.isOverdue;
      }
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    getBranchList() {
      this.$http.get("branchAgency/list").then(res => {
        this.branchList = res.data;
      });
    },
    getPackagingFormList() {
      this.$http.get("dicts/PackagingForm").then(res => {
        this.packagingFormList = res.data;
      });
    },
    getContractSourceList() {
      this.$http.get("dicts/ContractSource").then(res => {
        this.contractSourceList = res.data;
      });
    },
    downloadTemplate() {
      if (this.$i18n.isCn) {
        window.open("/file/合同导入模板.xlsx", "_self");
      } else {
        window.open("/file/ContractTemplate.xlsx", "_self");
      }
    },
    exportExcel() {
      this.exportLoading = true;
      this.$api
        .exportFile("contract/export", {}, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "合同信息.xlsx";
          } else {
            fileName = "Contract Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multiSelect = val;
      this.fileDisable = this.multiSelect.length > 1;
    },
    batchDelete() {
      if (!this.multiSelect.length) {
        this.$message.error(this.$l("contract.selectOneContract", "请至少选择一项合同"));
        return;
      }
      let contractIds = [];
      for (let item of this.multiSelect) {
        contractIds.push(item.id);
      }
      this.$confirm(this.$l("contract.voidedContract", "确认作废已选合同吗，已作废的合同不可恢复！"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.post("contract/batchDelete", contractIds).then(() => {
          this.getList(1);
          this.$message.success(this.$l("maintWorkOrder.tip.invalidSuccess", "作废成功"));
        }).catch(() => {
          this.$message.error(this.$l("maintWorkOrder.tip.invalidError", "作废失败"));
        });
      });
    },
    copyContract() {
      if (!this.multiSelect.length) {
        this.$message.error(this.$l("contract.firstSelectContract", "请先选择一项合同"));
        return;
      }
      if (this.multiSelect.length > 1) {
        this.$message.error(this.$l("contract.mostOneContract", "最多选择一项合同"));
        return;
      }
      this.$refs.editPage.open(this.multiSelect[0].id, true);
    },
    handleRowClick(row, column) {
      if (column && column.label === this.$t("common.operation")) {
        return;
      }
      this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
    },
    beforeUpload(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      let lastName = fileName.substring(index, fileName.length);
      if (lastName.toLowerCase() !== ".xlsx") {
        this.$message.error(this.$l("elevatorNetApply.uploadType", "请上传.xlsx文件类型"));
        return false;
      }
      this.importLoading = true;
      return true;
    },
    onUploadSuccess() {
      this.importLoading = false;
      this.$message.success(this.$l("elevatorNetApply.importSuccess", "导入成功"));
      this.getList(1);
    },
    onUploadError(err) {
      this.importLoading = false;
      if (!err.message) {
        this.$message.error(this.$l("elevatorNetApply.importFailure", "导入失败"));
      } else {
        this.$message.error(this.$l("elevatorNetApply.importFailure", "导入失败") + ": " + JSON.parse(err.message).message);
      }
    },
    onUploadExceed() {
      this.$message.error(this.$l("elevatorNetApply.importOnly", "一次只能导入1个文件"));
    },
    handleFileUpload() {
      if (this.multiSelect.length === 1) {
        this.$refs.contractFile.open(this.multiSelect[0]);
      } else {
        this.$message.error(this.$l("maintWorkOrder.tip.emptySingleRow", "请先选择一条合同") + "!");
      }
    },
  },
};
</script>

<style scoped></style>